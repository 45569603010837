import { InboxOutlined } from "@ant-design/icons";
import { Button, Input, Space, message } from "antd";
import { useState } from "react";
import { usePostSubscriptionsMutation } from "../../redux/Service/api";

const FooterTitle = () => {
  const [email, setEmail] = useState("");
  const [postSubscription, { isLoading }] = usePostSubscriptionsMutation();

  const handleSubscribe = async () => {
    if (!email) {
      message.error("Please enter your email.");
      return;
    }
    try {
      await postSubscription({ email }).unwrap();
      message.success("Subscribed successfully!");
      setEmail("");
    } catch (error) {
      message.error("Subscription failed. Please try again.");
    }
  };

  return (
    <div className="flex flex-wrap items-center justify-between pt-4 pb-4 border-b-2 vhilg:flex-col gap-y-[20px]">
      <div className="flex items-center gap-2 flex-nowrap ">
        <InboxOutlined className="text-[40px] miillg:text-[23px]" />
        <h2 className="text-[#0B3954] text-[30px] miillg:text-[15px]">
          Subscribe Now & Get Best Offers
        </h2>
      </div>
      <div>
        <Space.Compact className="w-[700px] miillg:w-[240px] h-[35px]">
          <Input
            placeholder="Your Email Address..."
            className="w-full h-full border-none text-[15px]"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="primary"
            className="h-full border-none bg-[#0780ad] uppercase miillg:pr-2 miillg:pl-2 miillg:text-[11px] !hover:bg-[#0780ad]"
            onClick={handleSubscribe}
            loading={isLoading}
          >
            Subscribe
          </Button>
        </Space.Compact>
      </div>
    </div>
  );
};

export default FooterTitle;

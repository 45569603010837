import { Button, Form, Input, Modal, Select, message } from "antd";
import { useAddCurrencyMutation } from "../../redux/Service/api";

const { Option } = Select;

const AddCurrency = ({ setOpenModal, openModal }: any) => {
  const [form] = Form.useForm();

  const [addCurrency, { isLoading }] = useAddCurrencyMutation();

  const handleAddCurrency = async (values: any) => {
    try {
      const res: any = await addCurrency(values);
      console.log("res", res);

      if (res?.data?.status == 0) {
        message.error(
          res?.data?.message || "Failed to add currency. Please try again."
        );
      } else {
        message.success("Currency added successfully");
        form.resetFields();
        setOpenModal(false);
      }
    } catch (error) {
      console.error("Error adding currency:", error);
      message.error("Failed to add currency. Please try again.");
    }
  };

  return (
    <Modal
      title="Add Currency"
      centered
      open={openModal}
      onCancel={() => {
        form.resetFields();
        setOpenModal(false);
      }}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddCurrency}
        autoComplete="off"
      >
        <Form.Item
          label="Currency Name"
          name="name"
          rules={[
            { required: true, message: "Please enter the currency name" },
          ]}
        >
          <Input size="large" placeholder="Enter currency name" />
        </Form.Item>

        <Form.Item
          label="Currency Symbol"
          name="symbol"
          rules={[
            { required: true, message: "Please enter the currency symbol" },
          ]}
        >
          <Input size="large" placeholder="Enter currency symbol" />
        </Form.Item>

        <Form.Item
          label="Currency Code"
          name="code"
          rules={[
            { required: true, message: "Please enter the currency code" },
          ]}
        >
          <Input size="large" placeholder="Enter currency code" />
        </Form.Item>

        <Form.Item
          label="Exchange Rate"
          name="rate"
          rules={[
            { required: true, message: "Please enter the exchange rate" },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: "Must be a valid number",
            },
          ]}
        >
          <Input size="large" placeholder="Enter exchange rate" />
        </Form.Item>

        {/* <Form.Item
          label="Default Currency"
          name="is_default"
          rules={[
            {
              required: true,
              message: "Please select if this is the default currency",
            },
          ]}
        >
          <Select size="large" placeholder="Select currency status">
            <Option value={true}>Yes</Option>
            <Option value={false}>No</Option>
          </Select>
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            Add
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddCurrency;

import { Button, Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useUplaodCategoryExcelMutation } from "../../redux/Service/api";

const UploadExcelButton = () => {
  const [uploadExcel, { isLoading }] = useUplaodCategoryExcelMutation();

  const handleUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      await uploadExcel(formData).unwrap();
      message.success("Excel file uploaded successfully");
    } catch (error) {
      console.error("Upload failed:", error);
      message.error("Failed to upload Excel file. Please try again.");
    }
  };

  return (
    <Upload
      customRequest={({ file }) => handleUpload(file as File)}
      showUploadList={false}
      accept=".xls,.xlsx"
    >
      <Button type="primary" ghost icon={<PlusOutlined />} loading={isLoading}>
        Upload Excel
      </Button>
    </Upload>
  );
};

export default UploadExcelButton;

import React, { useState } from "react";
import { DeleteFilled } from "@ant-design/icons";
import { Button, Modal, Table, message } from "antd";
import {
  useGetAllCurrenciesQuery,
  useDeleteCurrencyMutation,
} from "../../redux/Service/api";
import { ExclamationCircleFilled, EditFilled } from "@ant-design/icons";

import EditCurrency from "./EditCurrency";
import AddCurrency from "./AddCurrency";
import ServerError from "../../components/utils/ServerError";

const { confirm } = Modal;

const Currencies: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [entityForEdit, setEntityForEdit] = useState<any>(null);

  const [deleteCurrency, { isLoading: delIsLoading }] =
    useDeleteCurrencyMutation();
  const {
    data: currenciesData,
    isError: isCurrenciesError,
    isLoading: isCurrenciesLoading,
  } = useGetAllCurrenciesQuery(undefined);

  const currencies = currenciesData?.data ?? [];

  const handleDeleteCurrency = (id: number) => {
    confirm({
      title: "Are you sure you want to delete this currency?",
      icon: <ExclamationCircleFilled />,
      content: "Clicking OK will permanently delete the currency.",
      async onOk() {
        try {
          await deleteCurrency(id);
          message.success("Currency deleted successfully");
        } catch (error) {
          console.error(error);
          message.error("Failed to delete currency.");
        }
      },
    });
  };

  const columns = [
    {
      title: "Currency Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Currency Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Symbol",
      dataIndex: "symbol",
      key: "symbol",
    },
    {
      title: "Exchange Rate",
      dataIndex: "rate",
      key: "rate",
      render: (_, value) => {
        return (
          <>
            1 SAR = {parseFloat(value.rate).toFixed(2)} {value.name}
          </>
        );
      },
    },

    {
      title: "Actions",
      key: "actions",
      render: (_: any, currency: any) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditFilled />}
            onClick={() => {
              setEntityForEdit(currency);
              setOpenEditModal(true);
            }}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            danger
            onClick={() => handleDeleteCurrency(currency.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <h2 className="main_heading text-[1.5rem] font-bold">Currencies</h2>
      {isCurrenciesError && <ServerError />}

      <div className="text-center my-3">
        <Button
          className="w-[150px]"
          type="primary"
          onClick={() => setOpenModal(true)}
        >
          Add Currency
        </Button>
      </div>

      <div className="mx-auto">
        <Table
          columns={columns}
          dataSource={currencies}
          rowKey="id"
          loading={isCurrenciesLoading || delIsLoading}
          pagination={{ pageSize: 5 }}
        />
      </div>

      <AddCurrency openModal={openModal} setOpenModal={setOpenModal} />
      <EditCurrency
        openModal={openEditModal}
        setOpenModal={setOpenEditModal}
        entity={entityForEdit}
      />
    </section>
  );
};

export default Currencies;

import React from "react";
import { Form, Button, Upload, List, Image, message, Spin, Modal } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  useGetAgentsQuery,
  useSaveAgentsMutation,
  useDeleteAgentsMutation,
} from "../../redux/Service/api";

// Utility to normalize the file value from the Upload component
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const { confirm } = Modal;

const Agents = () => {
  const {
    data: agentsData,
    isLoading: isLoadingAgents,
    isError: isAgentsError,
  } = useGetAgentsQuery(undefined);
  const [saveAgent, { isLoading: isSavingAgent }] = useSaveAgentsMutation();
  const [deleteAgent, { isLoading: isDeletingAgent }] =
    useDeleteAgentsMutation();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    let photoFile = null;
    if (values.photo && values.photo.length > 0) {
      // Extract the uploaded file
      photoFile = values.photo[0].originFileObj || null;
    }
    if (!photoFile) {
      message.error("Please upload a photo.");
      return;
    }
    const body = new FormData();
    body.append("photo", photoFile);
    try {
      // Send the file as the photo field in the payload
      await saveAgent(body).unwrap();
      message.success("Agent added successfully.");
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error("Failed to add agent.");
    }
  };

  const handleDelete = (id) => {
    confirm({
      title: "Are you sure you want to delete this agent?",
      content: "This action cannot be undone.",
      onOk: async () => {
        try {
          await deleteAgent(id).unwrap();
          message.success("Agent deleted successfully.");
        } catch (error) {
          console.error(error);
          message.error("Failed to delete agent.");
        }
      },
      onCancel() {
        console.log("Delete action cancelled");
      },
    });
  };

  if (isLoadingAgents) {
    return <Spin />;
  }

  if (isAgentsError) {
    return <div>Error loading agents.</div>;
  }

  return (
    <div>
      {/* Form to add a new agent */}
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="flex gap-4 items-end"
      >
        <Form.Item
          name="photo"
          label="Agent Photo"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: true, message: "Please upload a photo" }]}
        >
          <Upload name="photo" listType="picture" beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>Upload Photo</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isSavingAgent}>
            Add Agent
          </Button>
        </Form.Item>
      </Form>

      {/* Display list of agents */}
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={agentsData?.data || []}
        renderItem={(agent: any) => (
          <List.Item>
            <div style={{ position: "relative" }}>
              <Image src={agent.photo} alt="agent" style={{ width: "100%" }} />
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(agent.id)}
                style={{ position: "absolute", top: 10, right: 10 }}
                // loading={isDeletingAgent}
              />
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Agents;

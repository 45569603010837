import { ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons";
import AcountNav from "../AcountDetails/AcountNav";
import { Link } from "react-router-dom";
import OrderGallary from "./OrderGallary";
import { useGetCartProductsQuery } from "../../redux/Service/api";


const Order = () => {
	const {
		data: cartData,
	} = useGetCartProductsQuery(undefined);
	console.log("cartData", cartData?.data?.data);

	return (
		<>
			<div className="text-center pt-10 pb-10 bg-[#fafafa]">
				<UserOutlined />
				<span>My account</span>
			</div>
			<div className="pr-[50px] miillg:gap-[30px] miillg:flex-wrap milgg:pr-[20%] justify-between flex mt-11 milgg:pl-[20%] pl-[50px] miilg:pr-[25px] miilg:pl-[25px] bg-[#fff] mb-14">
				<AcountNav />
				{
					cartData?.data?.data?.length ?
						<div className={`bg-red-400 w-full`}>
							<OrderGallary cartData={cartData} />
						</div>
						:

						<div className="bg-[#1565c0] maxlm:flex-col maxlm:items-center flex justify-between w-[70%] miillg:w-[100%] h-fit text-[#fff] pr-6 pl-6 pt-3 pb-3">
							<p>
								<ExclamationCircleOutlined className="pr-2" />
								<span className="text-[15px]">
									No order has been made yet.
								</span>
							</p>
							<Link to={"/shop"} className=" underline">
								Browse products
							</Link>
						</div>

				}


			</div>
		</>
	)
}

export default Order
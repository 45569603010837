import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: "https://back.konozelectric.com/",
  prepareHeaders: (headers, { endpoint }) => {
    const token = localStorage.getItem("knoozToken");
    const language = localStorage.getItem("language");

    headers.set("Accept-Language", language ? language : "ar");
    headers.set("Accept", "application/json");

    if (token && endpoint !== "login" && endpoint !== "register") {
      headers.set("Authorization", `Bearer ${token}`);
    }

    if (localStorage.getItem("konoz_currency")) {
      headers.set("currency", localStorage.getItem("konoz_currency"));
    }

    return headers;
  },
});

export const api = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: (builder) => ({
    // ********* Auth & User Management ********* //
    register: builder.mutation({
      query: (userData) => ({
        url: "api/auth/register",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    login: builder.mutation({
      query: (loginData) => ({
        url: "api/auth/login",
        method: "POST",
        body: loginData,
      }),
      invalidatesTags: ["User"],
    }),
    logout: builder.mutation({
      query: () => ({
        url: "api/auth/logout",
        method: "POST",
      }),
      invalidatesTags: ["User"],
    }),
    getLogedUser: builder.query({
      query: () => "api/auth/user",
      providesTags: ["User"],
    }),
    updateUserData: builder.mutation({
      query: (userData) => ({
        url: "api/auth/update",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    createAdmin: builder.mutation({
      query: (userData) => ({
        url: "api/admin/users",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["User"],
    }),
    adminGetAllUser: builder.query({
      query: () => "api/admin/users",
      providesTags: ["User"],
    }),
    deleteUser: builder.mutation({
      query: (userId) => ({
        url: `api/admin/terms/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),

    // ********* Categories ********* //
    getAllCategories: builder.query({
      query: () => "api/categories",
      providesTags: ["categories", "products"],
    }),
    uplaodCategoryExcel: builder.mutation({
      query: (formData) => ({
        url: "api/admin/categories/import",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "products"],
    }),
    getCategoryDetails: builder.query({
      query: (catId) => `api/categories/${catId}`,
      providesTags: ["categories", "products"],
    }),
    addCategory: builder.mutation({
      query: (formData) => ({
        url: "api/admin/categories",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "products"],
    }),
    deleteCategory: builder.mutation({
      query: (catId) => ({
        url: `api/admin/categories/${catId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categories", "products"],
    }),
    getFullCategoryDetails: builder.query({
      query: (id) => `api/categories/${id}/edit`,
      providesTags: ["categories", "products"],
    }),
    editCategory: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/admin/categories/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "products"],
    }),

    // ********* Brands ********* //
    getAllBrands: builder.query({
      query: () => "api/brands",
      providesTags: ["brands", "products"],
    }),
    addBrand: builder.mutation({
      query: (formData) => ({
        url: "api/admin/brands",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["brands", "products"],
    }),
    deleteBrand: builder.mutation({
      query: (catId) => ({
        url: `api/admin/brands/${catId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["brands", "products"],
    }),
    getFullBrandDetails: builder.query({
      query: (id) => `api/brands/${id}/edit`,
      providesTags: ["brands", "products"],
    }),
    editBrand: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/admin/brands/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["brands", "products"],
    }),

    // ********* Products ********* //
    getAllProducts: builder.query({
      query: () => "api/items?query=all",
      providesTags: ["products"],
    }),
    uplaodProductExcel: builder.mutation({
      query: (formData) => ({
        url: "api/admin/items/import",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["products"],
    }),
    getAllProductsByCategory: builder.query({
      query: (catId) => `api/items?category_id=${catId}`,
      providesTags: ["products"],
    }),
    getAllProductsPerPage: builder.query({
      query: (pageNumber) => `api/items?page=${pageNumber}`,
      providesTags: ["products"],
    }),

    getOneProduct: builder.query({
      query: (id) => `api/items/${id}`,
      providesTags: ["products"],
    }),

    getOneProductModal: builder.mutation({
      query: (id) => ({
        url: `api/items/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["products"],
    }),

    getAllSpecialProducts: builder.query({
      query: () => "api/items?is_special=1",
      providesTags: ["products"],
    }),
    addProduct: builder.mutation({
      query: (formData) => ({
        url: "api/admin/items",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "products"],
    }),
    deleteProduct: builder.mutation({
      query: (prodId) => ({
        url: `api/admin/items/${prodId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["categories", "products"],
    }),
    getFullProductDetails: builder.query({
      query: (id) => `api/items/${id}/edit`,
      providesTags: ["categories", "products"],
    }),
    editProduct: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/admin/items/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["categories", "products"],
    }),
    deleteProdPhoto: builder.mutation({
      query: (photoId) => ({
        url: `api/admin/photos/${photoId}`,
        method: "POST",
      }),
      invalidatesTags: ["categories", "products"],
    }),
    addProdPhoto: builder.mutation({
      query: ({ id, newPhotosFormData }) => ({
        url: `api/admin/item/photos/${id}`,
        method: "POST",
        body: newPhotosFormData,
      }),
      invalidatesTags: ["categories", "products"],
    }),
    makeItemSpecialOrNot: builder.mutation({
      query: (prodId) => ({
        url: `api/admin/items/special/${prodId}`,
        method: "POST",
      }),
      invalidatesTags: ["categories", "products"],
    }),

    // Banners
    getBanners: builder.query({
      query: () => "api/banners",
      providesTags: ["banners"],
    }),
    addBanner: builder.mutation({
      query: (formData) => ({
        url: "api/admin/banners",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["banners"],
    }),
    deleteBanner: builder.mutation({
      query: (bannerId) => ({
        url: `api/admin/banners/${bannerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["banners"],
    }),
    // Cart
    addToCart: builder.mutation({
      query: (cartBody) => ({
        url: "api/user/carts",
        method: "POST",
        body: cartBody,
      }),
      invalidatesTags: ["cart"],
    }),

    deleteCartItem: builder.mutation({
      query: (itemId) => ({
        url: `api/user/carts/${itemId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["cart"],
      onError: (error) => {
        console.error("Delete item error:", error);
      },
    }),

    getCartProducts: builder.query({
      query: () => "api/user/carts",
      providesTags: ["cart"],
    }),
    // **************** Favourites **************** //
    getFavouritesProducts: builder.query({
      query: () => "api/user/favorites",
      providesTags: ["favorites"],
    }),

    addOrRemoveFavouriteProduct: builder.mutation({
      query: (prodId) => ({
        url: "api/user/favorites",
        method: "POST",
        body: prodId,
      }),
      invalidatesTags: ["favorites", "products"],
    }),

    // ************** Currencies ************** //
    getAllCurrencies: builder.query({
      query: () => "api/currencies",
      providesTags: ["currencies"],
    }),
    addCurrency: builder.mutation({
      query: (formData) => ({
        url: "api/admin/currencies",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["currencies"],
    }),

    editCurrency: builder.mutation({
      query: ({ id, formData }) => ({
        url: `api/admin/currencies/${id}`,
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["currencies"],
    }),

    getCurrencyById: builder.query({
      query: (id) => `api/currencies/${id}`,
      providesTags: ["currencies"],
    }),

    deleteCurrency: builder.mutation({
      query: (currencyId) => ({
        url: `api/admin/currencies/${currencyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["currencies"],
    }),

    // Subscriptions

    getSubscroptions: builder.query({
      query: () => "api/admin/subscriptions",
      providesTags: ["subscriptions"],
    }),

    postSubscriptions: builder.mutation({
      query: (formData) => ({
        url: "api/subscriptions",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["subscriptions"],
    }),

    // Discount
    // update_discount
    updateDiscount: builder.mutation({
      query: (formData) => ({
        url: "api/admin/items/update_discount",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["discount", "categories", "products"],
    }),

    // About US
    // BasicData
    getAboutBasicData: builder.query({
      query: () => "api/about-us/1",
      providesTags: ["aboutUs"],
    }),
    saveAboutBasicData: builder.mutation({
      query: (formData) => ({
        url: "api/admin/about-us/1",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["aboutUs"],
    }),

    // Agents
    getAgents: builder.query({
      query: () => "api/agents",
      providesTags: ["agents"],
    }),
    saveAgents: builder.mutation({
      query: (formData) => ({
        url: "api/admin/agents",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["agents"],
    }),
    deleteAgents: builder.mutation({
      query: (agentId) => ({
        url: `api/admin/agents/${agentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["agents"],
    }),

    // Certificates
    getCertificates: builder.query({
      query: () => "api/certificates",
      providesTags: ["certificates"],
    }),
    saveCertificates: builder.mutation({
      query: (formData) => ({
        url: "api/admin/certificates",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["certificates"],
    }),
    deleteCertificates: builder.mutation({
      query: (certificateId) => ({
        url: `api/admin/certificates/${certificateId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["certificates"],
    }),
  }),
});

export const {
  // Auth & User Management
  useRegisterMutation,
  useLoginMutation,
  useLogoutMutation,
  useGetLogedUserQuery,
  useUpdateUserDataMutation,
  useCreateAdminMutation,
  useAdminGetAllUserQuery,
  useDeleteUserMutation,

  // Categories
  useGetAllCategoriesQuery,
  useGetCategoryDetailsQuery,
  useAddCategoryMutation,
  useDeleteCategoryMutation,
  useGetFullCategoryDetailsQuery,
  useEditCategoryMutation,
  useUplaodCategoryExcelMutation,

  // Brands
  useGetAllBrandsQuery,
  useAddBrandMutation,
  useDeleteBrandMutation,
  useGetFullBrandDetailsQuery,
  useEditBrandMutation,

  // Products
  useGetAllProductsQuery,
  useGetAllProductsByCategoryQuery,
  useGetAllProductsPerPageQuery,
  useGetOneProductQuery,
  useGetOneProductModalMutation,
  useGetAllSpecialProductsQuery,
  useAddProductMutation,
  useDeleteProductMutation,
  useGetFullProductDetailsQuery,
  useEditProductMutation,
  useDeleteProdPhotoMutation,
  useAddProdPhotoMutation,
  useMakeItemSpecialOrNotMutation,

  useUplaodProductExcelMutation,

  // Banners
  useGetBannersQuery,
  useAddBannerMutation,
  useDeleteBannerMutation,

  // cart
  useAddToCartMutation,
  useGetCartProductsQuery,
  useDeleteCartItemMutation,

  // favorites
  useGetFavouritesProductsQuery,
  useAddOrRemoveFavouriteProductMutation,

  // Currencies
  useGetAllCurrenciesQuery,
  useAddCurrencyMutation,
  useDeleteCurrencyMutation,
  useEditCurrencyMutation,
  useGetCurrencyByIdQuery,

  // Subscriptions
  useGetSubscroptionsQuery,
  usePostSubscriptionsMutation,

  // Discount
  // update_discount
  useUpdateDiscountMutation,

  // AboutUs
  useGetAboutBasicDataQuery,
  useSaveAboutBasicDataMutation,

  useGetAgentsQuery,
  useSaveAgentsMutation,
  useDeleteAgentsMutation,

  useGetCertificatesQuery,
  useSaveCertificatesMutation,
  useDeleteCertificatesMutation,
} = api;

import React from "react";
import { Tabs } from "antd";
import BasicData from "./BasicData";
import Agents from "./Agents";
import Certificates from "./Certificates";

const AboutSetting = () => {
  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <h2 className="main_heading text-[1.5rem] font-bold">About Settings</h2>
      <Tabs defaultActiveKey="basic">
        <Tabs.TabPane tab="Basic Data" key="basic">
          <BasicData />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Agents" key="agents">
          <Agents />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Certificates" key="certificates">
          <Certificates />
        </Tabs.TabPane>
      </Tabs>
    </section>
  );
};

export default AboutSetting;

import SchImage from "../../assets/image/schniderlogo.png";
import TestimonialsImage from "../../assets/image/schniderCERT.png";

// Import Swiper styles
import AboutSlider from "./AboutSlider";
import AboutSliderThreeD from "./AboutSliderThreeD";
import AboutTrustSection from "./AboutTrustSection";
const AboutTestimonials = () => {
  return (
    <>
      <div
        className={`bg-[#EDF4F4] pr-[25px] milgg:pr-[20%] milgg:pl-[20%] pl-[25px] miilg:pr-[20px] miilg:pl-[20px]
				pt-6 pb-[45px] flex justify-center items-center flex-col gap-[20px] text-[#0780ad] text-[30px] font-bold`}
      >
        <h2>Authorized distributor and panelbuilder for</h2>
        <div className="h-[120px]">
          <img src={SchImage} className="w-full h-full" alt="" />
        </div>
        <div className="mt-10 mb-10">
          <img src={TestimonialsImage} alt="" />
        </div>
        <h2 className="mb-10 ">Authorized Agent for</h2>
        <AboutSlider />
        <AboutSliderThreeD />
      </div>
      <div
        className={`bg-[#fff] pr-[25px] milgg:pr-[20%] milgg:pl-[20%] pl-[25px] miilg:pr-[20px] miilg:pl-[20px]
				pt-6 pb-[45px]`}
      >
        <AboutTrustSection />
      </div>
    </>
  );
};

export default AboutTestimonials;

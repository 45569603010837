import { useParams } from "react-router";
import ShopLayoutTitle from "../../components/shopLayoutTitle/ShopLayoutTitle";
import {
  useGetAllProductsByCategoryQuery,
  useGetCartProductsQuery,
} from "../../redux/Service/api";
import MenuRight from "../../components/common/menuRight/MenuRight";
import { Link } from "react-router-dom";
import SkeletonCard from "../../components/SkeletonCard";

const ShopCatogery = () => {
  const { id } = useParams();

  const selectedCurrency: any = localStorage.getItem("konoz_currency") || "0";

  const { data: allProductsData, isLoading: isAllProductsLoading } =
    useGetAllProductsByCategoryQuery(id);
  const allProducts = allProductsData?.data?.data ?? null;
  const { data: cartData } = useGetCartProductsQuery(undefined);
  const CardId = cartData?.data?.data?.map((ele: any) => {
    return ele?.id;
  });
  const allProductsDataEmpty =
    allProductsData?.data?.length === 0 && !isAllProductsLoading;

  return (
    <>
      <div className="flex flex-col">
        <ShopLayoutTitle
          title={"Home"}
          PathTo={"/"}
          Page={"Shop"}
          subPage={""}
        />
        {allProductsDataEmpty ? (
          <div className={``}>
            <p className={`text-[20px] font-[500] text-center`}>
              No products available at this time
            </p>
          </div>
        ) : (
          <div className="min-h-[50vh] grid grid-cols-4 vhilg:grid-cols-3 hiilg:grid-cols-2 maxlm:grid-cols-1 gap-4 items-start w-full ">
            {isAllProductsLoading &&
              Array(8)
                .fill(null)
                .map((_, index) => <SkeletonCard key={index} />)}
            {allProducts?.map((product: any) => {
              const hasDiscount = product?.discount < product?.price;
              return (
                <div
                  className="relative overflow-x-hidden duration-300 pb-[5px] group border-[1px] solid rounded-[8px] shadow-sm"
                  key={product?.id}
                >
                  <MenuRight product={product} CardId={CardId} />
                  <Link
                    to={`/shop/catogery/products/${product?.id}`}
                    className={`min-w-[21.2%]`}
                  >
                    <div
                      className={`h-[250px] vhilg:h-[300px] hiilg:h-[360px] w-full bg-[#cccccc79]`}
                    >
                      <img
                        src={product?.photo}
                        className={`w-full h-full 
                        border-b-[1px] solid 
                        rounded-tr-[8px] rounded-tl-[8px]`}
                        alt={product?.name}
                      />
                    </div>
                    <div className="flex flex-col items-center mt-4">
                      <p className="font-bold uppercase min-h-[50px] text-center">
                        {product?.name}
                      </p>
                      {/* <p className="text-[#888] font-bold text-[13px]">
                        <span className="pr-1">{product?.price}</span>
                      </p> */}
                      <div className="flex items-center gap-2">
                        {hasDiscount ? (
                          <>
                            <p className="text-black font-bold text-lg">
                              <span className="text-[0.7rem]">
                                {selectedCurrency == 0 ? "SAR " : "$ "}
                              </span>
                              {product?.discount.toFixed(2)}
                            </p>
                            <p className="text-[#888] font-bold text-[13px] line-through">
                              {product?.price.toFixed(2)}
                            </p>
                          </>
                        ) : (
                          <p className="text-black font-bold text-lg">
                            <span className="text-[0.7rem]">
                              {selectedCurrency == 0 ? "SAR " : "$ "}
                            </span>
                            {product?.price.toFixed(2)}
                          </p>
                        )}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default ShopCatogery;

import React from "react";
import { Link } from "react-router-dom";

const CartDetails = ({ allCartData }) => {
  return (
    <div
      className={`bg-[#fff] sticky top-0  w-[37.5%] hiilg:w-full p-5 h-fit `}
    >
      <p className="text-[#222] font-bold border-b-[1px] border-[#ccc] pb-[10px]">
        Cart totals
      </p>

      <p className="border-b-[1px] border-[#ccc] pb-[10px] mb-4 pt-[10px] flex justify-between flex-nowarp">
        <span className="text-[20px] uppercase font-bold hiilg:text-[15px]">
          Total
        </span>
        <span className="text-[18px] hiilg:text-[14px]">
          {allCartData.total_price}{" "}
          <span className="text-[#888] hiilg:text-[14px]">SAR</span>
        </span>
      </p>
      <Link
        to={"/Cart/checkout"}
        className="block text-center bg-[#0780ad] rounded-[5px] hover:bg-[#0b3954] duration-300 ease-linear text-[#fff] mb-3 pt-3 font-bold pb-3"
      >
        Proceed to checkout
      </Link>

      <Link
        to={"/shop"}
        className="block text-center bg-[#0b3954] rounded-[5px] hover:bg-[#0780ad] duration-300 ease-linear text-[#fff] pt-3 font-bold pb-3"
      >
        Continue shopping
      </Link>
    </div>
  );
};

export default CartDetails;

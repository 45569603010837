import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/autoplay";

import { Autoplay, EffectCoverflow } from "swiper/modules";
import img from "../../assets/image/certification-1.png";
import im2 from "../../assets/image/certification-2.png";
import { useMediaQuery } from "react-responsive";
import { useGetCertificatesQuery } from "../../redux/Service/api";

const AboutSliderThreeD = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" });
  const isMobile600 = useMediaQuery({ query: "(max-width: 600px)" });

  const {
    data: certificatesData,
    isLoading: isLoadingCertificates,
    isError: isCertificatesError,
  } = useGetCertificatesQuery(undefined);

  const certificates = certificatesData?.data ?? [];

  return (
    <div className="w-full mt-20 pb-[30px]">
      <Swiper
        className="h-[500px]"
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={isMobile ? (isMobile600 ? 1 : 2) : 3}
        spaceBetween={0}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, EffectCoverflow]}
      >
        {certificates?.map((certificate: any) => (
          <SwiperSlide className="maxlm:!flex maxlm:!justify-center">
            <img src={certificate.file} className="h-full" alt="Slide 1" />
          </SwiperSlide>
        ))}
        {(isLoadingCertificates || isCertificatesError) && (
          <>
            <SwiperSlide className="maxlm:!flex maxlm:!justify-center">
              <img src={img} className="h-full" alt="Slide 1" />
            </SwiperSlide>
            <SwiperSlide className="maxlm:!flex maxlm:!justify-center">
              <img src={im2} className="h-full" alt="Slide 2" />
            </SwiperSlide>
            <SwiperSlide className="maxlm:!flex maxlm:!justify-center">
              <img src={img} className="h-full" alt="Slide 3" />
            </SwiperSlide>
            <SwiperSlide className="maxlm:!flex maxlm:!justify-center">
              <img src={im2} className="h-full" alt="Slide 4" />
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </div>
  );
};

export default AboutSliderThreeD;

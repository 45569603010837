import FooterLogo from "../../assets/image/logo-e.png";
import {
  PhoneOutlined,
  AimOutlined,
  PaperClipOutlined,
  WhatsAppOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import FooterLeft from "../../assets/image/footerLeft.png";
import { Link } from "react-router-dom";

const FooterContent = () => {
  return (
    <>
      <div className="flex flex-wrap justify-between mt-2 border-t-2 solid hilg:flex-col">
        <div className="w-[60%] hilg:w-[100%] hilg:border-r-0 border-r-2 hilg:pr-0 pr-20">
          <div className="w-[250px] mt-4 mb-4 solid">
            <img src={FooterLogo} className="w-full" alt="Footer Logo" />
          </div>
          <p className="text-[#8C8C8C] mb-6">
            Providing clients with innovative ideas and practical solutions is
            one of our top priorities. These qualities have earned us a trusted
            reputation. Our resources are dedicated to fostering innovation,
            maintaining high standards, and delivering exceptional value.
          </p>
          <p className="mb-6 font-bold">
            Quick order – Material availability inquiry
          </p>
          <p className="mb-6">
            <PhoneOutlined className="text-[40px]" />
            <span className="text-[#0780ad] text-[30px] font-bold">
              +96612143255
            </span>
          </p>
          <p className="mb-6">
            <PhoneOutlined className="text-[40px]" />
            <span className="text-[#0780ad] text-[30px] font-bold">
              +966554608316
            </span>
          </p>
          <p className="mb-2">
            <AimOutlined className="text-[20px] pr-3" />
            <span className="text-[17px]">
              Al-Hidaya Street and Abdullah Al-khayyat - Al-Aziziyah South,
              Makkah
            </span>
          </p>
          <p className="mb-6">
            <PaperClipOutlined className="text-[20px] pr-3" />
            <a
              href="mailto:salem.mq@knoozelectric.com"
              className="text-[17px] hover:underline hover:text-blue-500"
            >
              salem.mq@knoozelectric.com
            </a>
          </p>
          <div className="w-full flex gap-4 items-center">
            <div className="w-[120px]">
              <img src={FooterLeft} className="w-full" alt="Footer Left" />
            </div>
            <a
              href="https://wa.me/966500350480"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppOutlined className="text-[40px] text-green-500" />
            </a>
          </div>
        </div>
        <div className="w-[35%] hilg:w-[50%] flex justify-between mt-4 maxlm:flex-col maxlm:gap-[20px]">
          {/* Social media icons */}
          <div className="flex gap-4">
            {/* <a
              href="https://wa.me/966500350480"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsAppOutlined className="text-[40px] text-green-500" />
            </a> */}
            {/* <a
              href="https://www.facebook.com/yourpage"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FacebookOutlined className="text-[40px] text-blue-600" />
            </a> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterContent;

import React, { useState } from "react";
import { Button, Form, Input } from "antd"; 
import type { FormProps } from "antd";
import { useRegisterMutation } from "../../redux/Service/api";
import { reduxLogin, saveUserData } from "../../redux/Slices/authSlice";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";



type FieldType = {
  email?: string;
  password?: string;
  remember?: string;
};


const AuthRegisterForm: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [createUser, { isLoading }] = useRegisterMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const onFinish = async (values: any) => {
    console.log("reg values", values);
    try {
      const result: any = await createUser(values);
      console.log("reg result", result);
      const token = result?.data?.data?.token ?? null;
      if (token) {
        localStorage.setItem("knoozToken", token);
        dispatch(reduxLogin());
        dispatch(saveUserData(result?.data?.data?.user));
        navigate("/");
      } else {
        setErrorMessage(
          result.error?.message ||
            result?.data?.message ||
            "something went wrong"
        );
        console.log("err");
      }
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };

  return (
    <div className="w-full Login p-4 border-[1px]">
			      <label className="text-[#0b3954] font-bold text-[23px] mb-6 block">Register</label>
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        style={{ maxWidth: 600 }}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="Email address"
          rules={[{ required: true, type: "email" }]}
        >
          <Input className="h-[40px]" />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            { required: true, message: "Please enter a valid password!" },
          ]}
        >
          <Input.Password className="h-[40px]" />
        </Form.Item>
        <Form.Item name="name" label="Full Name" rules={[{ required: true }]}>
          <Input className="h-[40px]" />
        </Form.Item> 
        <label className={`text-red-700 mb-4 font-[500] text-[16px] text-center w-full block`}>
          {errorMessage}
        </label>

        <label className="text-[12px] font-bold">
          Your personal data will be used to support your experience throughout
          this website, to manage access to your account, and for other purposes
          described in our privacy policy.
        </label>
        <Form.Item className="mt-6">
          <Button
            loading={isLoading}
            type="primary"
            htmlType="submit"
            className=" bg-[#0780ad] pr-7 pl-7 pt-4 pb-4 hover:!bg-[#0b3954] font-bold w-full text-[13px]"
          >
            Register
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AuthRegisterForm;

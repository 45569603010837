import React from "react";
import { Form, Button, Upload, List, Image, message, Spin, Modal } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  useGetCertificatesQuery,
  useSaveCertificatesMutation,
  useDeleteCertificatesMutation,
} from "../../redux/Service/api";

// Utility to normalize the file value from the Upload component
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const { confirm } = Modal;

const Certificates = () => {
  const {
    data: certificatesData,
    isLoading: isLoadingCertificates,
    isError: isCertificatesError,
  } = useGetCertificatesQuery(undefined);

  const [saveCertificate, { isLoading: isSavingCertificate }] =
    useSaveCertificatesMutation();
  const [deleteCertificate, { isLoading: isDeletingCertificate }] =
    useDeleteCertificatesMutation();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    let photoFile = null;
    if (values.photo && values.photo.length > 0) {
      // Extract the uploaded file
      photoFile = values.photo[0].originFileObj || null;
    }
    if (!photoFile) {
      message.error("Please upload a certificate photo.");
      return;
    }
    const body = new FormData();
    // body.append("photo", photoFile);
    body.append("file", photoFile);
    body.append("name", "test");

    try {
      // Send the file as the photo field in the payload
      await saveCertificate(body).unwrap();
      message.success("Certificate added successfully.");
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error("Failed to add certificate.");
    }
  };

  const handleDelete = (id) => {
    confirm({
      title: "Are you sure you want to delete this certificate?",
      content: "This action cannot be undone.",
      onOk: async () => {
        try {
          await deleteCertificate(id).unwrap();
          message.success("Certificate deleted successfully.");
        } catch (error) {
          console.error(error);
          message.error("Failed to delete certificate.");
        }
      },
      onCancel() {
        console.log("Delete action cancelled");
      },
    });
  };

  if (isLoadingCertificates) {
    return <Spin />;
  }

  if (isCertificatesError) {
    return <div>Error loading certificates.</div>;
  }

  return (
    <div>
      {/* Form to add a new certificate */}
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className="flex gap-4 items-end"
      >
        <Form.Item
          name="photo"
          label="Certificate Photo"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            { required: true, message: "Please upload a certificate photo" },
          ]}
        >
          <Upload name="photo" listType="picture" beforeUpload={() => false}>
            <Button icon={<UploadOutlined />}>Upload Photo</Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isSavingCertificate}
          >
            Add Certificate
          </Button>
        </Form.Item>
      </Form>

      {/* Display list of certificates */}
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={certificatesData?.data || []}
        renderItem={(certificate: any) => (
          <List.Item>
            <div style={{ position: "relative" }}>
              <Image
                src={certificate.file}
                alt="certificate"
                style={{ width: "100%" }}
              />
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => handleDelete(certificate.id)}
                style={{ position: "absolute", top: 10, right: 10 }}
                // loading={isDeletingCertificate}
              />
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Certificates;

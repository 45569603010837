import React from "react";
import { Tabs, Form, InputNumber, Select, Button, message } from "antd";
import {
  useGetAllCategoriesQuery,
  useGetAllProductsQuery,
  useUpdateDiscountMutation,
} from "../../redux/Service/api";
import ServerError from "../../components/utils/ServerError";

const Discount: React.FC = () => {
  const {
    data: categoriesData,
    isError: isCategoriesError,
    isLoading: isCategoriesLoading,
  } = useGetAllCategoriesQuery(undefined);
  const {
    data: productsData,
    isError: isProductsError,
    isLoading: isProductsLoading,
  } = useGetAllProductsQuery(undefined);

  const categories = categoriesData?.data ?? [];
  const products = productsData?.data?.data ?? [];

  const allCategories =
    getAllCategoriesWithChildren(categories)?.filter(
      (cat) => cat.parent_id !== null
    ) ?? [];

  function getAllCategoriesWithChildren(categories) {
    let allCategories = [];
    categories?.forEach((cat) => {
      allCategories.push(cat);
      if (cat.categories && cat.categories.length > 0) {
        allCategories = allCategories.concat(
          getAllCategoriesWithChildren(cat.categories)
        );
      }
    });
    return allCategories;
  }

  const [updateDiscount, { isLoading: isUpdating }] =
    useUpdateDiscountMutation();

  const handleCategoryDiscount = async (values: any) => {
    try {
      await updateDiscount({
        category_ids: values.selectedCategories,
        percent: values.discount,
      }).unwrap();
      message.success("Discount applied to selected categories successfully.");
    } catch (error) {
      console.error(error);
      message.error("Failed to apply discount to categories.");
    }
  };

  const handleProductDiscount = async (values: any) => {
    try {
      await updateDiscount({
        items_id: values.selectedProducts,
        percent: values.discount,
      }).unwrap();
      message.success("Discount applied to selected products successfully.");
    } catch (error) {
      console.error(error);
      message.error("Failed to apply discount to products.");
    }
  };

  if (isCategoriesError || isProductsError) {
    return <ServerError />;
  }

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <h2 className="main_heading text-[1.5rem] font-bold">Apply Discount</h2>
      <Tabs defaultActiveKey="categories">
        <Tabs.TabPane tab="Discount on Categories" key="categories">
          <Form layout="vertical" onFinish={handleCategoryDiscount}>
            <Form.Item
              label="Select Categories (one or more)"
              name="selectedCategories"
              rules={[
                {
                  required: true,
                  message: "Please select at least one category",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select categories"
                loading={isCategoriesLoading}
                showSearch
                filterOption={(input, option: any) => {
                  const lowerInput = input.toLowerCase();
                  return (
                    (option?.label &&
                      option.label.toLowerCase().includes(lowerInput)) ||
                    (option?.value &&
                      String(option.value).toLowerCase().includes(lowerInput))
                  );
                }}
              >
                {allCategories.map((category: any) => (
                  <Select.Option
                    key={category.id}
                    value={category.id}
                    label={category.name}
                  >
                    code: {category.id} - name: {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Discount Percentage"
              name="discount"
              rules={[
                { required: true, message: "Please enter discount percentage" },
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                placeholder="Discount %"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isUpdating}>
                Apply Discount
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Discount on Products" key="products">
          <Form layout="vertical" onFinish={handleProductDiscount}>
            <Form.Item
              label="Select Products (one or more)"
              name="selectedProducts"
              rules={[
                {
                  required: true,
                  message: "Please select at least one product",
                },
              ]}
            >
              <Select
                mode="multiple"
                placeholder="Select products"
                loading={isProductsLoading}
                showSearch
                filterOption={(input, option: any) => {
                  const lowerInput = input.toLowerCase();
                  return (
                    (option?.label &&
                      option.label?.toLowerCase().includes(lowerInput)) ||
                    (option?.value &&
                      String(option.value).toLowerCase().includes(lowerInput))
                  );
                }}
              >
                {products.map((product: any) => (
                  <Select.Option
                    key={product.id}
                    value={product.id}
                    label={product.name}
                  >
                    code: {product.id} - name: {product.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Discount Percentage"
              name="discount"
              rules={[
                { required: true, message: "Please enter discount percentage" },
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                placeholder="Discount %"
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isUpdating}>
                Apply Discount
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </section>
  );
};

export default Discount;

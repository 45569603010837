import { Button, Form, Input, Modal, Select, message } from "antd";
import { useEditCurrencyMutation } from "../../redux/Service/api";
import { useEffect } from "react";

const { Option } = Select;

const EditCurrency = ({ setOpenModal, openModal, entity }: any) => {
  const [form] = Form.useForm();

  const [editCurrency, { isLoading }] = useEditCurrencyMutation();

  useEffect(() => {
    form.setFieldsValue({
      name: entity?.name,
      symbol: entity?.symbol,
      code: entity?.code,
      rate: entity?.rate,
    });
  }, [entity, openModal]);

  const handleEditCurrency = async (values: any) => {
    try {
      const updatedData = { ...values, _method: "PUT" };
      await editCurrency({ id: entity?.id, formData: updatedData });
      message.success("Currency updated successfully");
      form.resetFields();
      setOpenModal(false);
    } catch (error) {
      console.error("Error updating currency:", error);
      message.error("Failed to update currency. Please try again.");
    }
  };

  return (
    <Modal
      title="Edit Currency"
      centered
      open={openModal}
      onCancel={() => {
        form.resetFields();
        setOpenModal(false);
      }}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleEditCurrency}
        autoComplete="off"
      >
        <Form.Item
          label="Currency Name"
          name="name"
          rules={[
            { required: true, message: "Please enter the currency name" },
          ]}
        >
          <Input size="large" placeholder="Enter currency name" />
        </Form.Item>

        <Form.Item
          label="Currency Symbol"
          name="symbol"
          rules={[
            { required: true, message: "Please enter the currency symbol" },
          ]}
        >
          <Input size="large" placeholder="Enter currency symbol" />
        </Form.Item>

        <Form.Item
          label="Currency Code"
          name="code"
          rules={[
            { required: true, message: "Please enter the currency code" },
          ]}
        >
          <Input size="large" placeholder="Enter currency code" />
        </Form.Item>

        <Form.Item
          label="Exchange Rate"
          name="rate"
          rules={[
            { required: true, message: "Please enter the exchange rate" },
            {
              pattern: /^[0-9]+(\.[0-9]+)?$/,
              message: "Must be a valid number",
            },
          ]}
        >
          <Input size="large" placeholder="Enter exchange rate" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading} block>
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCurrency;

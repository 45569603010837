import { Button, Flex, message, Modal, Spin, Table, Tooltip } from "antd";
import {
  ExclamationCircleFilled,
  PlusOutlined,
  DeleteFilled,
  StarOutlined,
  StarFilled,
  EditOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddProduct from "./AddProduct";
import EditProduct from "./EditProduct";
import Loading from "../../components/utils/Loading";
import ServerError from "../../components/utils/ServerError";
import {
  useDeleteProductMutation,
  useGetAllProductsQuery,
  useMakeItemSpecialOrNotMutation,
} from "../../redux/Service/api";
import UploadExcelButton from "./UploadExcelButton";

const { confirm } = Modal;

const ProductsControl = () => {
  const navigate = useNavigate();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [productIdForEdit, setProductIdForEdit] = useState(null);
  const [rerenderEditKey, setRerenderEditKey] = useState(0);
  const [modal, contextHolder] = Modal.useModal();

  const {
    data: allProductsData,
    isError: isProdsError,
    isLoading: isProdsLoading,
  } = useGetAllProductsQuery(undefined);

  const [switchSpecial, { isLoading: switchIsLoading }] =
    useMakeItemSpecialOrNotMutation();

  const [deleteProduct, { isLoading: delIsLoading }] =
    useDeleteProductMutation();

  const allProducts = allProductsData?.data?.data ?? [];

  const data = allProducts?.map((product) => {
    return {
      id: product.id,
      name: product.name,
      category: product.category?.name ?? null,
      brand: product.brand?.name ?? null,
      description: product.description ?? null,
      shortDescription: product.short_description ?? null,
      price: product.price,
      photo: product.photo,
      discount: product.discount,
    };
  });

  console.log(allProducts);

  const handleDeleteProduct = async (id) => {
    confirm({
      title: "Are you sure you want to delete this product?",
      icon: <ExclamationCircleFilled />,
      content: "Click OK to confirm product deletion.",
      async onOk() {
        try {
          const res = await deleteProduct(id);
          res.data.message
            ? message.info(res.data.message)
            : message.success("Product deleted successfully");
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const handleEditProduct = (id) => {
    setProductIdForEdit(id);
    setOpenEditModal(true);
    setRerenderEditKey((prev) => prev + 1);
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Photo",
      dataIndex: "photo",
      key: "photo",
      render: (photo) =>
        photo ? (
          <img
            src={photo}
            alt="Product"
            style={{
              width: 50,
              height: 50,
              objectFit: "cover",
              borderRadius: 5,
            }}
          />
        ) : (
          <span>No Image</span>
        ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    // {
    //   title: "Brand",
    //   dataIndex: "brand",
    //   key: "brand_id",
    // },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: "Short Description",
      dataIndex: "shortDescription",
      key: "shortDescription",
      ellipsis: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (text, record) => {
        console.log(record);

        return (
          <div>
            <span
              className={
                record.discount && record.discount !== "0"
                  ? "line-through text-gray-500"
                  : ""
              }
            >
              {record.price}
            </span>
            {record.discount && record.discount !== "0" && (
              <span className="text-red-500 ml-2"> {record.discount}</span>
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Special",
    //   dataIndex: "special",
    //   key: "special",
    //   render: (special, record) => (
    //     <Tooltip title={special ? "Make not special" : "Make special"}>
    //       <Button
    //         type="text"
    //         icon={special ? <StarFilled /> : <StarOutlined />}
    //         onClick={() => switchSpecial(record.id)}
    //         loading={switchIsLoading}
    //       />
    //     </Tooltip>
    //   ),
    // },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="flex gap-2">
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEditProduct(record.id)}
          />
          <Button
            type="text"
            icon={<DeleteFilled />}
            onClick={() => handleDeleteProduct(record.id)}
            danger
          />
        </div>
      ),
    },
  ];

  return (
    <section className="products section-padding mt-0 pt-0 relative">
      {/* <h2 className="main-heading text-center">Our Products</h2> */}
      <h2 className="main_heading text-[1.5rem] font-bold">Our Products</h2>

      {contextHolder}
      {isProdsError && <ServerError />}

      <Flex className="justify-center mb-5 gap-4">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setOpenAddModal(true)}
        >
          Add Product
        </Button>
        <UploadExcelButton />
      </Flex>

      <div className="container mx-auto my-5">
        <Table
          loading={isProdsLoading}
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={{ pageSize: 10 }}
        />
      </div>

      <AddProduct
        openAddModal={openAddModal}
        setOpenAddModal={setOpenAddModal}
      />
      <EditProduct
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        productIdForEdit={productIdForEdit}
        rerenderEditKey={rerenderEditKey}
      />
    </section>
  );
};

export default ProductsControl;

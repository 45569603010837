import React, { useEffect } from "react";
import { Form, Input, Button, message, Spin, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {
  useGetAboutBasicDataQuery,
  useSaveAboutBasicDataMutation,
} from "../../redux/Service/api";

// Normalize the value from Upload component
const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const BasicData = () => {
  const {
    data: aboutData,
    isLoading,
    isError,
  } = useGetAboutBasicDataQuery(undefined);

  const [saveAboutBasicData, { isLoading: isSaving }] =
    useSaveAboutBasicDataMutation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (aboutData && aboutData.data) {
      // Prepare initial file list for photo if URL exists
      const initialPhoto = aboutData.data.photo
        ? [
            {
              uid: "-1",
              name: "photo.png",
              status: "done",
              url: aboutData.data.photo,
            },
          ]
        : [];
      form.setFieldsValue({
        photo: initialPhoto,
        mission: aboutData.data.mission,
        trust_text: aboutData.data.trust_text,
        address: aboutData.data.address,
        phones: aboutData.data.phones,
        email: aboutData.data.email,
      });
    }
  }, [aboutData, form]);

  const onFinish = async (values) => {
    let photoFile = undefined;
    console.log("values", values);

    if (values.photo && values.photo.length > 0) {
      photoFile = values.photo[0].originFileObj || null;
    }

    console.log("photoFile", photoFile);

    const payload = new FormData();
    if (photoFile) {
      payload.append("photo", photoFile);
    }
    payload.append("_method", "PUT");
    payload.append("mission", values.mission);
    payload.append("trust_text", values.trust_text);
    payload.append("address", values.address);
    payload.append("phones", values.phones);
    payload.append("email", values.email);

    try {
      await saveAboutBasicData(payload).unwrap();
      message.success("Data saved successfully");
    } catch (error) {
      console.error(error);
      message.error("Failed to save data");
    }
  };

  if (isLoading) {
    return <Spin />;
  }

  if (isError) {
    return <div>Error loading data</div>;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      className="grid grid-cols-2 gap-4"
    >
      <Form.Item
        label="Title"
        name="mission"
        rules={[{ required: true, message: "Please input the mission" }]}
      >
        <Input.TextArea placeholder="Mission" />
      </Form.Item>
      <Form.Item
        className="col-span-2"
        label="Discription"
        name="trust_text"
        rules={[{ required: true, message: "Please input the trust text" }]}
      >
        <Input.TextArea placeholder="Trust Text" />
      </Form.Item>
      <Form.Item
        label="Address"
        name="address"
        rules={[{ required: true, message: "Please input the address" }]}
      >
        <Input placeholder="Address" />
      </Form.Item>
      <Form.Item
        label="Phones"
        name="phones"
        rules={[{ required: true, message: "Please input the phone numbers" }]}
      >
        <Input placeholder="Phones" />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            type: "email",
            message: "Please input a valid email",
          },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        label="Logo"
        name="photo"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: "Please upload a photo" }]}
      >
        <Upload
          name="photo"
          listType="picture"
          beforeUpload={() => false} // Prevent auto upload
        >
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          size="large"
          type="primary"
          htmlType="submit"
          loading={isSaving}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BasicData;

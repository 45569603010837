import img from "../../assets/image/CAVALO-PIC.png";
import AboutCollapse from "./AboutCollapse";
const AboutTrustSection = () => {
  return (
    <div className="text-[30px] text-[#0780ad] bg-[#fff] mt-11">
      <h2 className="mb-11 maxlm:text-[18px]">
        Here's why you can trust Konoz Store:
      </h2>

      <div className="flex flex-col gap-[20px]">
        <p className="text-[16px] maxlm:text-[14px] text-[#7A7A7A] font-normal">
          <span>1. </span>
          <span className="leading-[25px]">
            Quality Products: We are committed to offering only the highest
            quality equestrian products. We carefully curate our inventory,
            selecting trusted brands and manufacturers known for their
            craftsmanship and attention to detail. Rest assured that every item
            you purchase from Konoz Store has undergone rigorous quality checks
            to meet our stringent standards.
          </span>
        </p>
        <p className="text-[16px] maxlm:text-[14px] text-[#7A7A7A] font-normal">
          <span>2. </span>
          <span className="leading-[25px]">
            Expert Knowledge: Our team consists of experienced equestrians who
            are passionate about the sport and understand the unique needs of
            riders and their horses. We continuously stay updated with the
            latest industry trends, advancements, and best practices to provide
            you with accurate and reliable information. Whether you need
            assistance in choosing the right equipment or have specific
            questions about horse care, our knowledgeable staff is here to help.
          </span>
        </p>
        <p className="text-[16px] maxlm:text-[14px] text-[#7A7A7A] font-normal">
          <span>3. </span>
          <span className="leading-[25px]">
            Customer Satisfaction: We prioritize customer satisfaction above all
            else. We value your feedback and strive to exceed your expectations
            in every interaction. From providing exceptional customer service to
            offering hassle-free returns and exchanges, we are dedicated to
            ensuring that you are delighted with your Konoz Store experience.
          </span>
        </p>
        <p className="text-[16px] maxlm:text-[14px] text-[#7A7A7A] font-normal">
          <span>4. </span>
          <span className=" leading-[25px]">
            Secure Transactions: We take your privacy and security seriously.
            Our website is equipped with industry-standard encryption technology
            to safeguard your personal and financial information. You can shop
            with confidence knowing that your data is protected and that we
            adhere to strict privacy policies.
          </span>
        </p>
      </div>
      <div className="flex gap-[100px] miillg:flex-col  hiilg:gap-[40px] hiilg:justify-between mt-11 ">
        <div className="w-[35%] miillg:w-[100%] miillg:max-h-[unset] miillg:h-[650px] maxlm:h-[400px] hiilg:w-[45%] about_image max-h-[540px]">
          <img src={img} className="w-full h-full" alt="" />
        </div>
        <div className="w-[50%] miillg:w-[100%] aboutCollapse">
          <AboutCollapse />
        </div>
      </div>
    </div>
  );
};

export default AboutTrustSection;

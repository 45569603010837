import React, { useState } from "react";
import type { FormProps } from "antd";
import { Button, Checkbox, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLoginMutation } from "../../redux/Service/api";
import { reduxLogin, saveUserData } from "../../redux/Slices/authSlice";

type FieldType = {
  username?: string;
  password?: string;
  remember?: string;
};



const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const AuthLoginForm: React.FC = () => {

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginUser, { isLoading, isError, error }] = useLoginMutation();
  const [errorMessage, setErrorMessage] = useState("");

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    console.log("login values:", values);
    // return;
    if(values.username && values?.username){
      try {
        const formdata = new FormData();
        formdata.append('email', values.username);
        formdata.append('password', values.password);
        const result: any = await loginUser(formdata);
        console.log("login result", result);
  
        const token = result?.data?.data?.token ?? null;
        if (token) {
          dispatch(reduxLogin());
          localStorage.setItem("knoozToken", token);
          dispatch(saveUserData(result?.data?.data?.user));
          navigate("/");
        } else {
          setErrorMessage(
            result.error?.message ||
              result?.data?.message ||
              "something went wrong"
          );
          console.log("err");
        }
      } catch (error) {
        console.error("Registration failed:", error);
      }
    }

  };




  return (
    <div className="w-full p-4 border-[1px] Login solid">
      <label className="text-[#0b3954] font-bold text-[23px]">Login</label>
      <Form
      form = {form}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="!w-full p-0 mt-[20px]"
      >
        <Form.Item<FieldType>
          label="Username or email"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input className="h-[40px]" />
        </Form.Item>

        <Form.Item<FieldType>
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password className="h-[40px]" />
        </Form.Item>

        <Form.Item<FieldType> name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="bg-[#0780ad] pr-7 pl-7 pt-4 pb-4 hover:!bg-[#0b3954] font-bold w-full"
          >
            Login
          </Button>
        </Form.Item>
        <Link to={""} className="block w-full text-center underline">
          Lost your password?
        </Link>
      </Form>
    </div>
  );
};

export default AuthLoginForm;

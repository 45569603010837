import OrderGallaryBox from "./OrderGallaryBox";

const OrderGallary = ({cartData}: any) => {


  return (
    <div className={`bg-[#fff] sticky top-0  w-full p-5 h-fit`}>
      <p className="text-[#222] font-bold  pb-[10px]">
        Your order
      </p>
			<div className={`grid grid-cols-3 hilg:grid-cols-2 maxlm:grid-cols-1 gap-[10px]`}>
      {
        cartData?.data?.data?.map((ele : any)=>{
          return (<OrderGallaryBox key={ele?.id}
          id = {ele?.id}
          title = {ele?.name}
          image = {ele?.photo}
          price = {ele?.price}
          total = {ele?.total}   
          quantity = {ele?.quantity}       
          />
        )})
      }
			</div>
      <p className="pb-[10px] mb-4 pt-[10px] flex justify-between flex-nowarp">
        <span className="text-[20px] uppercase font-bold">Total</span>
        <span className="text-[18px]">{cartData?.data?.total_price} SAR</span>
      </p>
    </div>
  );
};

export default OrderGallary;

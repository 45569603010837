import {
  HeartOutlined,
  HomeFilled,
  UserOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import "./Banner.scss";
import { useSelector } from "react-redux";
import BannerDrawer from "./BannerDrawer";
const Banner = () => {
  const { isAuthenticated, userData } = useSelector((state: any) => state.auth);

  return (
    <div
      className={`bg-[#0781ad5d] pr-[50px] milgg:pr-[20%] milgg:pl-[20%] pl-[50px] miilg:pr-[20px] Banner
      miilg:pl-[20px] miillg:fixed miillg:bottom-0 miillg:z-50 miillg:bg-[#ffffff] miillg:w-full miillg:shadow-[2px_0_12px_0_rgba(0,0,0,0.15)] `}
    >
      <div className="flex flex-row items-center gap-8 flex-nowrap text-[#0b3954]  miillg:justify-between">
        <NavLink
          to={"/"}
          className="miillg:flex miillg:flex-col miillg:items-center h-full block pt-2 pb-2"
        >
          <HomeFilled className="text-[20px] miillg:text-[30px] text-[#0b3954]" />
          <span className="miillg:text-[13px] niillg:hidden">Home</span>
        </NavLink>

        {
          isAuthenticated === true ?
            <NavLink
              to={"/acountDetails"}
              className="miillg:flex miillg:flex-col miillg:items-center niillg:hidden"
            >
              <UserOutlined className="text-[20px] miillg:text-[30px] text-[#0b3954]" />
              <span className="miillg:text-[13px] niillg:hidden">Profile</span>
            </NavLink>
            :

            <NavLink
              to={"/Acount"}
              className="miillg:flex miillg:flex-col miillg:items-center niillg:hidden"
            >
              <UserOutlined className="text-[20px] miillg:text-[30px] text-[#0b3954]" />
              <span className="miillg:text-[13px] niillg:hidden">Sign in</span>
            </NavLink>
        }


        <BannerDrawer />

        <NavLink to={"/shop"} className="miillg:hidden h-full block pt-2 pb-2 font-[600]">
          Shop
        </NavLink>
        <NavLink
          to={"/aboutus"}
          className="miillg:hidden h-full block pt-2 pb-2 font-[600]"
        >
          About us
        </NavLink>
        <NavLink
          to={"/contactus"}
          className="miillg:hidden h-full block pt-2 pb-2 font-[600]"
        >
          Contact Us
        </NavLink>
        {
          isAuthenticated &&
          <NavLink
            to={"/wishlist"}
            className=" items-center gap-1 miillg:hidden h-full block pt-2 pb-2"
          >
            <HeartOutlined />
            <span className="pl-2 font-[600]">Wishlist</span>
          </NavLink>
        }
        {(isAuthenticated && userData?.role !== 'client') && (
          <NavLink
            to="/dashboard"
            className="miillg:hidden h-full block pt-2 pb-2 font-[600]"
          >
            <span>Dashboard</span>
          </NavLink>
        )}
      </div>

      <div></div>
    </div>
  );
};

export default Banner;

import { useState, useEffect } from "react";
import { Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

const CurrencyDropdown = ({ currencies, getCurrenciesLoading }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(
    localStorage.getItem("konoz_currency") || "0"
  );

  useEffect(() => {
    setSelectedCurrency(localStorage.getItem("konoz_currency") || "0");
  }, []);

  const handleMenuClick = ({ key }) => {
    localStorage.setItem("konoz_currency", key);
    window.location.reload();
  };

  const items = currencies.map((currency) => ({
    key: String(currency?.id),
    label: currency?.name,
  }));

  const allItems: MenuProps["items"] = [
    {
      key: "0",
      label: "SAR",
    },
    ...items,
  ];
  const displayText =
    selectedCurrency === "0"
      ? "SAR"
      : currencies.find((currency) => String(currency.id) === selectedCurrency)
          ?.name || selectedCurrency;

  return (
    <Dropdown menu={{ items: allItems, onClick: handleMenuClick }}>
      <span className="cursor-pointer">
        <Space>
          {getCurrenciesLoading ? "Loading..." : displayText}
          <DownOutlined />
        </Space>
      </span>
    </Dropdown>
  );
};

export default CurrencyDropdown;

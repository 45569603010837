import React from "react";
import { Table } from "antd";
import { useGetSubscroptionsQuery } from "../../redux/Service/api";
import ServerError from "../../components/utils/ServerError";

const Subscriptions: React.FC = () => {
  const {
    data: subscriptionsData,
    isError: isSubscriptionsError,
    isLoading: isSubscriptionsLoading,
  } = useGetSubscroptionsQuery(undefined);

  const subscriptions = subscriptionsData?.data ?? [];

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Subscribed At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date: string) => new Date(date).toLocaleString(),
    },
  ];

  return (
    <section className="p-4 section_padding mt-0 pt-0 relative">
      <h2 className="main_heading text-[1.5rem] font-bold">Subscriptions</h2>
      {isSubscriptionsError && <ServerError />}
      <div className="mx-auto px-6">
        <Table
          columns={columns}
          dataSource={subscriptions}
          rowKey="id"
          loading={isSubscriptionsLoading}
          pagination={{ pageSize: 5 }}
        />
      </div>
    </section>
  );
};

export default Subscriptions;

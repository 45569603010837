type IProps = {
  id : number,
  title : string,
  image : string,
  price : string,
  total : string,   
  quantity : number,
}
const OrderGallaryBox = ({id , title , image , price , total , quantity}: IProps) => {
  
  return (
    <div key={id} className="flex gap-4 border-[1px] border-[#ccc] pt-5 pb-5 maxllmmm:pl-5 flex-nowarp maxllmmm:flex-col ">
      <div className="w-[70px] h-[70px] ">
        <img src={image} className="w-full h-full" alt={title} />
      </div>
      <div>
        <p>{title}</p>
        <p className="text-[#8c8c8c]">{quantity} × {price} SAR</p>
        <p>
          <span>Subtotal:</span>
          <span className="#8c8c8c pl-2">{price} SAR</span>
        </p>
      </div>
    </div>
  );
};

export default OrderGallaryBox;
